import React, { useEffect, useMemo, useState } from "react";
import { useForm } from "react-hook-form";
import { Form, Modal, ModalBody } from "reactstrap";
import { useGetLocalZoneOptions } from "../../../../api/shipping/localclasses";
import { useGetZoneOptions } from "../../../../api/shipping/zones";
import { Button, Col, Icon, RSelect, Row } from "../../../../components/Component";

const ClassModal = ({ modal, closeModal, onSubmit, formData, setFormData, filterStatus, type, isLocal }) => {
  const { data: zones, isLoading } = useGetZoneOptions();
  const { data: localZones, isLoading: loading } = useGetLocalZoneOptions();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  // console.log(zones);

  const [selectedZone, setselectedZone] = useState("");
  //ROLE Options
  const zoneOptions = useMemo(() => {
    if (!isLoading) {
      return zones?.data?.map((zone, idx) => {
        return {
          id: zone._id,
          label: zone.name,
          value: zone.name,
        };
      });
    }
  }, [zones, isLoading]);

  const localZoneOptions = useMemo(() => {
    if (!loading) {
      return localZones?.data?.map((zone, idx) => {
        return {
          id: zone._id,
          label: zone.name,
          value: zone.name,
        };
      });
    }
  }, [localZones, loading]);

  const selectZone = (e) => {
    setselectedZone(e.value);
    setFormData({ ...formData, zone: e.id });
  };
  // console.log(zoneOptions);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  return (
    <Modal isOpen={modal} toggle={() => closeModal()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            closeModal();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{type.add ? "Add Class" : "Edit Class"}</h5>
          <div className="mt-4">
            <Form className="row gy-4" noValidate onSubmit={handleSubmit(onSubmit)}>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("name", { required: "This field is required" })}
                    defaultValue={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    placeholder="Enter name"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Select Zone </label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={isLocal ? localZoneOptions : zoneOptions}
                      value={{
                        value: selectedZone,
                        label: selectedZone,
                      }}
                      onChange={(e) => selectZone(e)}
                    />
                  </div>
                  {errors.weight && <span className="invalid">{errors.weight.message}</span>}
                </div>
              </Col>
              <Col md="6">
                <span className="form-label" style={{ display: "block", marginBottom: "0.9rem" }}>
                  Select Function
                </span>
                <Row className="gy-4">
                  <Col sm="6">
                    <div className="preview-block">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="+"
                          name="function"
                          className="custom-control-input"
                          checked={formData.classFunction === "+"}
                          value="+"
                          onChange={(e) => setFormData({ ...formData, classFunction: e.target.value })}
                        />
                        <label className="custom-control-label" htmlFor="+">
                          Add (+)
                        </label>
                      </div>
                    </div>
                  </Col>
                  <Col sm="6">
                    <div className="preview-block">
                      <div className="custom-control custom-radio">
                        <input
                          type="radio"
                          id="-"
                          name="function"
                          className="custom-control-input"
                          checked={formData.classFunction === "-"}
                          value="-"
                          onChange={(e) => setFormData({ ...formData, classFunction: e.target.value })}
                        />
                        <label className="custom-control-label" htmlFor="-">
                          Minus (-)
                        </label>
                      </div>
                    </div>
                  </Col>
                </Row>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Percentage(%)</label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("percentage", { required: "This field is required" })}
                    defaultValue={formData.percentage}
                    onChange={(e) => setFormData({ ...formData, percentage: e.target.value })}
                  />

                  {/* {errors.phone && <span className="invalid">{errors.phone.message}</span>} */}
                </div>
              </Col>

              <Col md="12">
                <div className="form-group">
                  <label className="form-label" htmlFor="description">
                    Description
                  </label>
                  <div className="form-control-wrap">
                    <textarea
                      type="textarea"
                      className="form-control form-control-sm"
                      id="fv-message"
                      {...register("description", {
                        required: true,
                      })}
                      placeholder="Write class description"
                      onChange={(e) => setFormData({ ...formData, description: e.target.value })}
                    />
                    {/* {errors.message && <span className="invalid">This field is required</span>} */}
                  </div>
                </div>
              </Col>

              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {type.add ? "Add Class" : "Edit Class"}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        closeModal();
                      }}
                      className="link link-dark"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ClassModal;
