import React, { Suspense, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  useCreateLocalZones,
  useDeleteLocalZones,
  useEditLocalZones,
  useGetAllLocalZones,
} from "../../../../../api/shipping/localzones";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../../../../components/Component";
import Content from "../../../../../layout/content/Content";
import Head from "../../../../../layout/head/Head";
import LoadingSpinner from "../../../../components/spinner";
import ZonesTable from "../../tables/ZonesTable";
import ZoneModal from "../ZoneModal";

const LocalShippingZones = () => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const [editId, setEditId] = useState("");

  const itemsPerPage = searchParams.get("limit") ?? 7;
  const currentPage = searchParams.get("page") ?? 1;
  const { isLoading, data: localZones } = useGetAllLocalZones(currentPage, itemsPerPage);
  const { mutate: createLocalZone } = useCreateLocalZones();
  const { mutate: updateZone } = useEditLocalZones();
  const { mutate: deleteZone } = useDeleteLocalZones(editId);

  // console.log(localZones);

  const [view, setView] = useState({
    add: false,
    edit: false,
  });

  const [formData, setFormData] = useState({
    name: "",
    // weight: "",
    states: [], //role id
    // amount: "",
    // status: "Active",
  });
  //   console.log(data);
  const onEditClick = (id) => {
    localZones?.data.forEach((item) => {
      if (item._id === id) {
        // console.log(item.states);
        const selectedStates = item.states.map((item) => {
          return { label: item, value: item };
        });
        setEditId(item._id);

        setFormData({
          name: item.name,
          // weight: { label: item.weight, value: item.weight },
          states: selectedStates,
          // amount: item.amount,
        });
        setView({ edit: true, add: false });
      }
    });
  };

  const formSubmit = () => {
    const { name, states } = formData;

    let selectedStates = states.map((state) => state.value);

    let data = {
      name,
      states: selectedStates,
      // weight,
      // amount,
    };
    // console.log(data);
    if (view.add) {
      createLocalZone(data);
    } else {
      updateZone({ zone: editId, ...data });
    }

    closeModal();
  };

  const toggle = (type) => {
    setView({
      add: type === "add" ? true : false,
      edit: type === "edit" ? true : false,
    });
  };

  const closeModal = () => {
    setView({ add: false, edit: false });
    resetForm();
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      // weight: "",
      states: [], //role id
      // amount: "",
    });
  };

  const ActionOptions = ({ id, name }) => {
    return (
      <ul className="nk-tb-actions gx-1">
        <li>
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="btn btn-icon dropdown-toggle btn-trigger">
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdown"
                    onClick={(ev) => {
                      ev.preventDefault();
                      navigate(`/shipping/local/zone-weights/${id}?name=${name}`);
                      // onEditClick(id);
                    }}
                  >
                    <Icon name="eye"></Icon>
                    <span>View weights</span>
                  </DropdownItem>
                </li>

                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdown"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onEditClick(id);
                    }}
                  >
                    <Icon name="edit"></Icon>
                    <span>Edit zone</span>
                  </DropdownItem>
                </li>

                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdown"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setEditId(id);
                      deleteZone();
                    }}
                  >
                    <Icon name="trash"></Icon>
                    <span>Delete</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    );
  };

  // console.log(data);

  return (
    <React.Fragment>
      <Head title="Shipping Zones"></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Shipping Zones</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className="toggle btn-icon d-md-none"
                  color="primary"
                  onClick={() => {
                    toggle("add");
                  }}
                >
                  <Icon name="plus"></Icon>
                </Button>
                <Button
                  className="toggle d-none d-md-inline-flex"
                  color="primary"
                  onClick={() => {
                    toggle("add");
                  }}
                >
                  <Icon name="plus"></Icon>
                  <span>Create Zone</span>
                </Button>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {/*  TABLE HERE */}
        <Block>
          <Suspense fallback={<LoadingSpinner />}>
            <ZonesTable
              tableData={localZones}
              loading={isLoading}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              actions={ActionOptions}
              isLocal
            />
          </Suspense>
        </Block>
      </Content>

      <ZoneModal
        onSubmit={formSubmit}
        formData={formData}
        setFormData={setFormData}
        closeModal={closeModal}
        modal={view.add || view.edit}
        type={view}
        isLocal
      />
    </React.Fragment>
  );
};

export default LocalShippingZones;
