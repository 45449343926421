export const storeData = [
  {
    id: 1,
    storename: "StellarBites",
    storeId: "95954",
    date: "Jun 4, 2020",
    username: "Arnold Armstrong",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    status: "Inactive",
    purchased: "3 items",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
    total: "45,000",
    email: "admin@stellarbites.com",
    address: "Ebonyi",
    postalCode: "05784",
    verificationStatus: "Unverified",
    accountStatus: "Unverified",
  },
  {
    id: 2,
    storeId: "95961",
    storename: "QuantumTech Solutions",
    date: "Jun 3, 2020",
    username: "Jean Douglas",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    status: "Active",
    purchased: "Fitness Tracker",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
    total: "25,000",
    email: "admin@QuantumTechSolutions.com",
    address: "Abuja",
    postalCode: "05790",
    verificationStatus: "Verified",
    accountStatus: "Verified",
  },
  {
    id: 3,
    storeId: "95963",
    storename: "LunarLuxe Spas",
    date: "May 29, 2020",
    username: "Ashley Lawson",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    status: "Active",
    purchased: "Pink Fitness Tracker",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
    total: "10,000",
  },
  {
    id: 4,
    storename: "Sparkle & Sip Jewelry",

    storeId: "95933",
    date: "May 29, 2020",
    username: "Joe Larson",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    status: "Inactive",
    purchased: "2 items",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
    total: "40,000",
  },
  {
    id: 5,
    storename: "CosmoClean Services",

    storeId: "95947",
    date: "May 28, 2020",
    username: "Frances Burns",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    status: "Active",
    purchased: "6 items",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
    total: "65,000",
  },
  {
    id: 6,
    storename: "ElectroVibe Fitness",

    storeId: "95909",
    date: "May 26, 2020",
    username: "Victoria Lynch",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    status: "Restricted",
    purchased: "Waterproof Speakers",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
    total: "25,000",
  },
  {
    id: 7,
    storename: "ZephyrZest Clothing",

    storeId: "95902",
    date: "May 25, 2020",
    username: "Patrick Newman",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    status: "Restricted",
    purchased: "4 items",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
    total: "60,000",
  },
  {
    id: 8,
    storename: "SereneScape Retreats",

    storeId: "95996",
    date: "May 24, 2020",
    username: "Emma Walker",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    status: "Active",
    purchased: "Smart Watch",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
    total: "21,500",
  },
  {
    id: 9,
    storename: "FusionFlavors Catering",

    storeId: "95982",
    date: "May 23, 2020",
    status: "Active",
    username: "Jane Montegomery",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    purchased: "Smart Watch",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
    total: "15,000",
  },
  {
    id: 10,
    storename: "RoamRunner Travel Agency",

    storeId: "95959",
    date: "May 23, 2020",
    status: "Inactive",
    username: "Jane Harris",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    purchased: "Waterproof Speaker",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
    total: "25,000",
  },
  {
    id: 11,
    storename: "EnigmaEscape Games",

    storeId: "95810",
    date: "Jul 4, 2020",
    status: "Inactive",
    username: "Jakoby Roman",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    purchased: "3 items",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
  },
  {
    id: 12,
    storename: "Buttermilk factory",

    storeId: "95820",
    date: "Jul 3, 2020",
    username: "Katherine Moss",
    totalProducts: 8,
    ngn: "10,000",
    usd: "3,000",
    status: "Active",
    purchased: "Fitness Tracker",
    location: "Lagos/NG",
    check: false,
    phone: "08010022444",
  },
];

export const filterStoreStatus = [
  {
    label: "Inactive",
    value: "Inactive",
  },
  {
    label: "Active",
    value: "Active",
  },
  {
    label: "Restricted",
    value: "Restricted",
  },
];
