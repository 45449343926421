import React, { useState } from "react";
import { useNavigate, useParams } from "react-router";
import {
  useCreateLocalZoneWeight,
  useDeleteLocalZoneWeight,
  useEditLocalZoneWeights,
  useGetAllLocalZonesWeights,
} from "../../../../../api/shipping/localzones";
import { useSearchParams } from "react-router-dom";
import Head from "../../../../../layout/head/Head";
import Content from "../../../../../layout/content/Content";
import {
  Block,
  BlockHead,
  BlockTitle,
  BlockBetween,
  BlockHeadContent,
  Icon,
  Button,
} from "../../../../../components/Component";
import { UncontrolledDropdown, DropdownToggle, DropdownMenu, DropdownItem } from "reactstrap";
import { Suspense } from "react";
import LoadingSpinner from "../../../../components/spinner";
import WeightsTable from "../../tables/WeightTable";
import WeightModal from "../WeightModal";

const LocalZoneWeightsPage = () => {
  const { zoneId } = useParams();
  const navigate = useNavigate();
  const [searchParams] = useSearchParams();
  const [editId, setEditId] = useState(null);

  const itemsPerPage = searchParams.get("limit") ?? 7;
  const currentPage = searchParams.get("page") ?? 1;
  const zoneName = searchParams.get("name") ?? "";

  const { mutate: createWeight } = useCreateLocalZoneWeight();
  const { data: weights, isLoading } = useGetAllLocalZonesWeights(zoneId, currentPage, itemsPerPage);
  const { mutate: updateWeight } = useEditLocalZoneWeights();
  const { mutate: deleteWeight } = useDeleteLocalZoneWeight(editId);

  // console.log(weights);

  const [view, setView] = useState({
    add: false,
    edit: false,
  });

  const [formData, setFormData] = useState({
    // name: "",
    weight: "",
    // countries: [], //role id
    amount: "",
    // status: "Active",
  });

  const onEditClick = (id) => {
    weights?.data.forEach((item) => {
      if (item._id === id) {
        setEditId(item._id);
        setFormData({
          weight: item.key,
          amount: item.amount,
        });
        setView({ edit: true, add: false });
      }
    });
  };

  const formSubmit = () => {
    const { weight, amount } = formData;

    let data = {
      zone: zoneId,
      key: weight,
      amount,
    };
    if (view.add) {
      createWeight(data);
    } else {
      updateWeight({ weightId: editId, ...data });
    }

    closeModal();
  };

  const toggle = (type) => {
    setView({
      add: type === "add" ? true : false,
      edit: type === "edit" ? true : false,
    });
  };

  const closeModal = () => {
    setView({ add: false, edit: false });
    resetForm();
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      weight: "",
      amount: "",
    });
  };

  const ActionOptions = ({ id }) => {
    return (
      <ul className="nk-tb-actions gx-1">
        <li>
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="btn btn-icon dropdown-toggle btn-trigger">
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdown"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onEditClick(id);
                    }}
                  >
                    <Icon name="edit"></Icon>
                    <span>Edit weight</span>
                  </DropdownItem>
                </li>

                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdown"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setEditId(id);
                      deleteWeight();
                    }}
                  >
                    <Icon name="trash"></Icon>
                    <span>Delete</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    );
  };

  return (
    <React.Fragment>
      <Head title={`${zoneName}`}></Head>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Name: {zoneName}</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <div className="toggle-expand-content">
                  <ul className="nk-block-tools g-3">
                    <li>
                      <Button
                        className="toggle d-none d-md-inline-flex"
                        color="primary"
                        onClick={() => {
                          toggle("add");
                        }}
                      >
                        <Icon name="plus"></Icon>
                        <span>Add Weight</span>
                      </Button>
                    </li>

                    <li className="nk-block-tools-opt">
                      <Button
                        color="light"
                        outline
                        className="bg-white d-none d-sm-inline-flex"
                        onClick={() => navigate(-1)}
                      >
                        <Icon name="arrow-left"></Icon>
                        <span>Back</span>
                      </Button>
                    </li>
                  </ul>
                </div>
                <div className="d-inline-flex d-sm-none">
                  <Button
                    className="toggle btn-icon d-md-none me-3"
                    color="primary"
                    onClick={() => {
                      toggle("add");
                    }}
                  >
                    <Icon name="plus"></Icon>
                  </Button>
                  <div className="nk-block-tools-opt">
                    <a
                      href="#back"
                      onClick={(ev) => {
                        ev.preventDefault();
                        navigate(-1);
                      }}
                      className="btn btn-icon btn-outline-light bg-white d-inline-flex d-sm-none"
                    >
                      <Icon name="arrow-left"></Icon>
                    </a>
                  </div>
                </div>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {/* PRODUCT TABLE HERE */}
        <Block>
          <Suspense fallback={<LoadingSpinner />}>
            <WeightsTable
              tableData={weights}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              actions={ActionOptions}
              loading={isLoading}
            />
          </Suspense>
        </Block>
      </Content>

      <WeightModal
        onSubmit={formSubmit}
        formData={formData}
        setFormData={setFormData}
        closeModal={closeModal}
        modal={view.add || view.edit}
        type={view}
      />
    </React.Fragment>
  );
};

export default LocalZoneWeightsPage;
