import React from "react";
import { Route } from "react-router-dom";
import { CustomerProvider } from "../pages/main/dashboard/customer/CustomerContext";

import EcomOrder from "../pages/main/dashboard/order/Orders";
import EcomProducts from "../pages/main/dashboard/product/ProductList";
import EcomCustomer from "../pages/main/dashboard/customer/CustomerList";
import EcomCustomerDetails from "../pages/main/dashboard/customer/CustomerDetails";
// import EcomSettings from "../pages/main/dashboard/settings/Settings";
import EcomDashboard from "../pages/main/dashboard/index";
import ProductDetailPage from "../pages/main/dashboard/product/ProductDetail";
import EcomStores from "../pages/main/dashboard/stores/StoreList";

import Error404Classic from "../pages/error/404-classic";
import Error404Modern from "../pages/error/404-modern";
import Error504Modern from "../pages/error/504-modern";
import Error504Classic from "../pages/error/504-classic";

import Login from "../pages/auth/Login";
import ForgotPassword from "../pages/auth/ForgotPassword";

import Layout from "../layout/Index";
import LayoutNoSidebar from "../layout/Index-nosidebar";
import OrderDetails from "../pages/main/dashboard/order/OrderDetails";
import ProductReviewPage from "../pages/main/dashboard/product/ProductReview";

import ProtectedRoute from "./ProtectedRoute";
import StoreDetailsPageTwo from "../pages/main/dashboard/stores";
import PayoutPage from "../pages/main/dashboard/payouts/PayoutPage";
import CategoriesPage from "../pages/main/dashboard/catelog/CategoriesPage";
import SubCategoriesPage from "../pages/main/dashboard/catelog/SubCategories";
import ColorsPage from "../pages/main/dashboard/catelog/Colors";
import ProductTypePage from "../pages/main/dashboard/catelog/ProductType";
import BrandsPage from "../pages/main/dashboard/catelog/Brands";
import CouponsPage from "../pages/main/dashboard/coupons/CouponsPage";
import UserList from "../pages/main/dashboard/users/UserList";
import UserDetailsPage from "../pages/main/dashboard/users/UserDetails";
import AdminList from "../pages/main/dashboard/admin/adminList";
import RolesList from "../pages/main/dashboard/roles/rolesList";
import CreateRoles from "../pages/main/dashboard/roles/createRoles";
import EditRoles from "../pages/main/dashboard/roles/editRoles";
import UserInfoPage from "../pages/main/dashboard/settings/UserInfo";
import UserSecurityPage from "../pages/main/dashboard/settings/Security";
import SingleOrders from "../pages/main/dashboard/order/SingleOrders";
import IntlShippingZones from "../pages/main/dashboard/shipping/intl/Zones";
import IntlShippingClass from "../pages/main/dashboard/shipping/intl/Class";
import LocalShippingZones from "../pages/main/dashboard/shipping/local/Zones";
import LocalShippingClass from "../pages/main/dashboard/shipping/local/Class";
import ZoneWeightsPage from "../pages/main/dashboard/shipping/intl/ZoneWeights";
import LocalZoneWeightsPage from "../pages/main/dashboard/shipping/local/ZoneWeights";

import { createBrowserRouter, RouterProvider, createRoutesFromElements } from "react-router-dom";
import LoadingSpinner from "../pages/components/spinner";
import ChangePassword from "../pages/auth/ChangePassword";
import OtherSettingsPage from "../pages/main/dashboard/settings/OtherSettings";

const routes = (
  <Route>
    <>
      {/*Panel */}
      <Route element={<ProtectedRoute />}>
        <Route path={`${process.env.PUBLIC_URL}`} element={<Layout />}>
          <Route index element={<EcomDashboard />}></Route>
          <Route path="orders">
            <Route path="general" element={<EcomOrder />} />
            <Route path="single" element={<SingleOrders />} />
            <Route
              path="order-details/:orderId"
              element={
                <React.Suspense fallback={<LoadingSpinner />}>
                  <OrderDetails />
                </React.Suspense>
              }
            />
          </Route>
          <Route path="products">
            <Route index element={<EcomProducts />} />
            <Route
              path="product-details/:productId"
              element={
                <React.Suspense fallback={<LoadingSpinner />}>
                  <ProductDetailPage />
                </React.Suspense>
              }
            ></Route>
            <Route path="product-reviews/:productId" element={<ProductReviewPage />}></Route>
          </Route>
          <Route
            path="stores"
            element={
              <React.Suspense fallback={<LoadingSpinner />}>
                <EcomStores />
              </React.Suspense>
            }
          ></Route>

          <Route element={<CustomerProvider />}>
            <Route path="customer" element={<EcomCustomer />}></Route>y
            <Route path="customer-details/:customerId" element={<EcomCustomerDetails />}></Route>y
          </Route>
          <Route></Route>
          <Route path="store-details/:storeId" element={<StoreDetailsPageTwo />}></Route>
          <Route>
            <Route path="categories" element={<CategoriesPage />}></Route>
            <Route path="subcategory" element={<SubCategoriesPage />}></Route>
            <Route path="brands" element={<BrandsPage />}></Route>
            <Route path="colors" element={<ColorsPage />}></Route>
            <Route path="product-type" element={<ProductTypePage />}></Route>
          </Route>
          <Route path="payouts" element={<PayoutPage />}></Route>
          <Route path="coupons" element={<CouponsPage />}></Route>

          <Route path="user-management" element={<UserList />}></Route>
          <Route path="user-details/:userId" element={<UserDetailsPage />}></Route>
          <Route path="admin-management" element={<AdminList />}></Route>
          <Route path="roles-management" element={<RolesList />}></Route>
          <Route path="create-roles" element={<CreateRoles />}></Route>
          <Route path="edit-roles/:roleId" element={<EditRoles />}></Route>
          <Route path="settings">
            <Route index element={<UserInfoPage />} />
            <Route path="user-security" element={<UserSecurityPage />} />
            <Route path="other-settings" element={<OtherSettingsPage />} />
          </Route>
          <Route path="shipping">
            <Route path="intl/zones" element={<IntlShippingZones />} />
            <Route path="intl/zone-weights/:zoneId" element={<ZoneWeightsPage />} />
            <Route path="intl/class" element={<IntlShippingClass />} />
            <Route path="local/zones" element={<LocalShippingZones />} />
            <Route path="local/class" element={<LocalShippingClass />} />
            <Route path="local/zone-weights/:zoneId" element={<LocalZoneWeightsPage />} />
          </Route>
        </Route>
      </Route>

      <Route path={`${process.env.PUBLIC_URL}`} element={<LayoutNoSidebar />}>
        <Route path="auth-reset" element={<ForgotPassword />}></Route>
        <Route path="auth-login" element={<Login />}></Route>
        <Route path="auth-change-password" element={<ChangePassword />} />
        <Route path="errors">
          <Route path="404-modern" element={<Error404Modern />}></Route>
          <Route path="404-classic" element={<Error404Classic />}></Route>
          <Route path="504-modern" element={<Error504Modern />}></Route>
          <Route path="504-classic" element={<Error504Classic />}></Route>
        </Route>
        <Route path="*" element={<Error404Modern />}></Route>
      </Route>
    </>
  </Route>
);

const Router = () => {
  const routers = createBrowserRouter(createRoutesFromElements(routes));

  return <RouterProvider router={routers} />;
};
export default Router;
