import React, { useState } from "react";
import { useSearchParams } from "react-router-dom";
import Head from "../../../../../layout/head/Head";
import {
  useCreateLocalClass,
  useDeleteLocalClass,
  useEditLocalClass,
  useGetAllLocalClass,
} from "../../../../../api/shipping/localclasses";
import ShippingClass from "../components/Class";

const LocalShippingClass = () => {
  const [searchParams] = useSearchParams();
  const [editId, setEditId] = useState("");

  const itemsPerPage = searchParams.get("limit") ?? 7;
  const currentPage = searchParams.get("page") ?? 1;

  const { mutate: createLocalClass } = useCreateLocalClass();
  const { isLoading, data: localClasses } = useGetAllLocalClass(currentPage, itemsPerPage);
  const { mutate: updateLocalClass } = useEditLocalClass();
  const { mutate: deleteLocalClass } = useDeleteLocalClass(editId);

  return (
    <>
      <Head title="Local Shipping Class"></Head>
      <ShippingClass
        editId={editId}
        setEditId={setEditId}
        isLoading={isLoading}
        createClass={createLocalClass}
        classData={localClasses}
        updateClass={updateLocalClass}
        deleteClass={deleteLocalClass}
        currentPage={currentPage}
        itemsPerPage={itemsPerPage}
        isLocal
      />
    </>
  );
};

export default LocalShippingClass;
