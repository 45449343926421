import React, { Suspense, useState } from "react";
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap";
import {
  Block,
  BlockBetween,
  BlockHead,
  BlockHeadContent,
  BlockTitle,
  Button,
  Icon,
} from "../../../../../components/Component";
import Content from "../../../../../layout/content/Content";
import LoadingSpinner from "../../../../components/spinner";
import ClassTable from "../../tables/ClassTable";
import ClassModal from "../ClassModal";

const ShippingClass = ({
  editId,
  setEditId,
  createClass,
  classData,
  updateClass,
  deleteClass,
  isLoading,
  currentPage,
  itemsPerPage,
  isLocal,
}) => {
  //   const { mutate: createIntlClass } = useCreateIntlClass();
  //   const { data: intlClasses, isLoading } = useGetAllIntlClass(currentPage, itemsPerPage);
  //   const { mutate: updateIntlClass } = useEditIntlClass();
  //   const { mutate: deleteIntlClass } = useDeleteIntlClass(editId);

  // console.log(intlClasses);
  const [view, setView] = useState({
    add: false,
    edit: false,
  });

  const [formData, setFormData] = useState({
    name: "",
    classFunction: "+",
    percentage: "", //role id
    zone: "",
    description: "",
    // status: "Active",
  });
  //   console.log(data);
  const onEditClick = (id) => {
    classData?.data?.forEach((item) => {
      if (item._id === id) {
        setEditId(item._id);
        // console.log(item);
        setFormData({
          name: item.name,
          classFunction: item.classFunction,
          zone: { label: item.zone, value: item.zone, id: item.id },
          percentage: item.percentage,
          description: item.description,
        });
        // setSelectedRole(item.role.title);
        setView({ edit: true, add: false });
      }
    });
  };

  const formSubmit = () => {
    // console.log(formData);
    // const { name, weight, countries, amount } = formData;
    const { name, zone, description, classFunction, percentage } = formData;

    // let selectedCountries = countries.map((country) => country.value);

    let data = {
      name,
      zone,
      classFunction,
      percentage,
      description,
    };
    // console.log(data);
    if (view.add) {
      createClass(data);
      //   createIntlClass(data);
    } else {
      updateClass({ classId: editId, ...data });
      //   updateIntlClass({ classId: editId, ...data });
    }

    closeModal();
  };

  const toggle = (type) => {
    setView({
      add: type === "add" ? true : false,
      //   details: type === "details" ? true : false,
      edit: type === "edit" ? true : false,
    });
  };

  const closeModal = () => {
    setView({ add: false, edit: false });
    resetForm();
  };

  // function to reset the form
  const resetForm = () => {
    setFormData({
      name: "",
      classFunction: "+",
      percentage: "",
      zone: "",
      description: "",
    });
  };

  const ActionOptions = ({ id }) => {
    return (
      <ul className="nk-tb-actions gx-1">
        <li>
          <UncontrolledDropdown>
            <DropdownToggle tag="a" className="btn btn-icon dropdown-toggle btn-trigger">
              <Icon name="more-h"></Icon>
            </DropdownToggle>
            <DropdownMenu end>
              <ul className="link-list-opt no-bdr">
                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdown"
                    onClick={(ev) => {
                      ev.preventDefault();
                      onEditClick(id);
                    }}
                  >
                    <Icon name="edit"></Icon>
                    <span>Edit class</span>
                  </DropdownItem>
                </li>

                <li>
                  <DropdownItem
                    tag="a"
                    href="#dropdown"
                    onClick={(ev) => {
                      ev.preventDefault();
                      setEditId(id);
                      deleteClass();
                      //   setProductId(item?._id);
                      //   deleteNew(item._id);
                    }}
                  >
                    <Icon name="trash"></Icon>
                    <span>Delete</span>
                  </DropdownItem>
                </li>
              </ul>
            </DropdownMenu>
          </UncontrolledDropdown>
        </li>
      </ul>
    );
  };

  // console.log(data);

  return (
    <React.Fragment>
      <Content>
        <BlockHead size="sm">
          <BlockBetween>
            <BlockHeadContent>
              <BlockTitle>Shipping Class</BlockTitle>
            </BlockHeadContent>
            <BlockHeadContent>
              <div className="toggle-wrap nk-block-tools-toggle">
                <Button
                  className="toggle btn-icon d-md-none"
                  color="primary"
                  onClick={() => {
                    toggle("add");
                  }}
                >
                  <Icon name="plus"></Icon>
                </Button>
                <Button
                  className="toggle d-none d-md-inline-flex"
                  color="primary"
                  onClick={() => {
                    toggle("add");
                  }}
                >
                  <Icon name="plus"></Icon>
                  <span>Create Class</span>
                </Button>
              </div>
            </BlockHeadContent>
          </BlockBetween>
        </BlockHead>
        {/* PRODUCT TABLE HERE */}
        <Block>
          <Suspense fallback={<LoadingSpinner />}>
            <ClassTable
              tableData={classData}
              loading={isLoading}
              currentPage={currentPage}
              itemsPerPage={itemsPerPage}
              actions={ActionOptions}
            />
          </Suspense>
        </Block>
      </Content>

      <ClassModal
        onSubmit={formSubmit}
        formData={formData}
        setFormData={setFormData}
        closeModal={closeModal}
        modal={view.add || view.edit}
        type={view}
        isLocal={isLocal}
      />
    </React.Fragment>
  );
};

export default ShippingClass;
