import { Country, State } from "country-state-city";
import React, { useEffect } from "react";
import { useForm } from "react-hook-form";
import { Form, Modal, ModalBody } from "reactstrap";
import { Button, Col, Icon, RSelect } from "../../../../components/Component";

const ZoneModal = ({ modal, closeModal, onSubmit, formData, setFormData, type, isLocal }) => {
  const nigerianStates = State.getStatesOfCountry("NG");
  const allCountries = Country.getAllCountries();

  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();

  //State options for react select
  const stateOptions = nigerianStates.map((state) => {
    if (state.name === "Abuja Federal Capital Territory") {
      return {
        label: "Abuja",
        value: "Abuja",
      };
    }
    return {
      label: state.name,
      value: state.name,
    };
  });

  //Country options for react-select
  const countriesOptions = allCountries.map((country) => {
    return {
      label: country.name,
      value: country.name,
    };
  });

  // console.log(countriesOptions);
  // console.log(stateOptions);

  //select countries
  const selectCountries = (e) => {
    setFormData({ ...formData, countries: e });
  };

  const selectStates = (e) => {
    setFormData({ ...formData, states: e });
  };

  //function to close modal
  const close = () => {
    closeModal();
  };

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  return (
    <Modal isOpen={modal} toggle={() => close()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            close();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{type.add ? "Add Zone" : "Edit Zone"}</h5>
          <div className="mt-4">
            <Form className="row gy-4" noValidate onSubmit={handleSubmit(onSubmit)}>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Name</label>
                  <input
                    className="form-control"
                    type="text"
                    {...register("name", { required: "This field is required" })}
                    defaultValue={formData.name}
                    onChange={(e) => setFormData({ ...formData, name: e.target.value })}
                    placeholder="Enter name"
                  />
                  {errors.name && <span className="invalid">{errors.name.message}</span>}
                </div>
              </Col>

              {isLocal ? (
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Select State</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={stateOptions}
                        isMulti
                        value={formData.states}
                        onChange={(e) => selectStates(e)}
                      />
                    </div>
                  </div>
                </Col>
              ) : (
                <Col md="6">
                  <div className="form-group">
                    <label className="form-label">Select Country</label>
                    <div className="form-control-wrap">
                      <RSelect
                        options={countriesOptions}
                        isMulti
                        value={formData.countries}
                        onChange={(e) => selectCountries(e)}
                      />
                    </div>
                  </div>
                </Col>
              )}
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {type.add ? "Add Zone" : "Edit Zone"}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        close();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default ZoneModal;
