const menu = [
  {
    heading: "Welcome",
  },
  {
    icon: "dashboard-fill",
    text: "Dashboard",
    link: "/",
    permission: "all",
  },
  {
    icon: "bag-fill",
    text: "Orders",
    permission: "GETORD",
    subMenu: [
      {
        text: "General orders",
        link: "/orders/general",
        permission: "GETORD",
      },
      {
        text: "Single orders",
        link: "/orders/single",
        permission: "GETORD",
      },
    ],
    // link: "/orders",
  },
  {
    icon: "package-fill",
    text: "Products",
    link: "/products",
    permission: "GETPDT",
  },
  {
    icon: "home-fill",
    text: "Stores/Businesses",
    link: "/stores",
    permission: "GETSTR",
  },
  {
    icon: "list",
    text: "Catalogs",
    permission: "all",
    subMenu: [
      {
        text: "Categories",
        link: "/categories",
        permission: "GETCAT",
      },
      {
        text: "Sub categories",
        link: "/subcategory",
        permission: "GETSUBCAT",
      },
      {
        text: "Brands",
        link: "/brands",
        permission: "GETBRD",
      },
      {
        text: "Colors",
        link: "/colors",
        permission: "GETCLR",
      },
      {
        text: "Product Type",
        link: "/product-type",
        permission: "GETCAT",
      },
    ],
  },
  {
    icon: "coins",
    text: "Payouts",
    link: "/payouts",
    permission: "GETWDL",
  },
  {
    icon: "cards",
    text: "Coupons",
    link: "/coupons",
    permission: "GETCPN",
  },
  {
    heading: "Shipping",
  },
  {
    icon: "globe",
    text: "International",
    permission: "all",
    subMenu: [
      {
        text: "Shipping zones",
        link: "/shipping/intl/zones",
        permission: "all",
      },
      {
        text: "Shipping class",
        link: "/shipping/intl/class",
        permission: "all",
      },
    ],
  },
  {
    icon: "truck",
    text: "Local",
    permission: "all",
    subMenu: [
      {
        text: "Shipping zones",
        link: "/shipping/local/zones",
        permission: "all",
      },
      {
        text: "Shipping class",
        link: "/shipping/local/class",
        permission: "all",
      },
    ],
  },

  {
    heading: "Manage Users",
  },
  {
    icon: "users-fill",
    text: "Users",
    link: "/user-management",
    permission: "GETUSR",
  },
  {
    icon: "user-alt-fill",
    text: "Admin",
    link: "/admin-management",
    permission: "GETADM",
  },
  {
    icon: "layers-fill",
    text: "Roles",
    link: "/roles-management",
    permission: "GETADM",
  },
  {
    heading: "preferences",
  },
  // {
  //   icon: "account-setting",
  //   text: "Account",
  //   link: "/account settings",
  // },
  {
    icon: "opt-alt-fill",
    text: "Settings",
    link: "/settings",
    permission: "all",
  },
];
export default menu;
