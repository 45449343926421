import React, { useEffect, useMemo } from "react";
import { useForm } from "react-hook-form";
import { Form, Modal, ModalBody } from "reactstrap";
import { useGetShippingWeights } from "../../../../api/generics";
import { Button, Col, Icon, RSelect } from "../../../../components/Component";

const WeightModal = ({ modal, closeModal, onSubmit, formData, setFormData, filterStatus, type }) => {
  const { data: weights, isLoading: loadingWeights } = useGetShippingWeights();
  const {
    reset,
    register,
    handleSubmit,
    formState: { errors },
  } = useForm();
  const weightOptions = useMemo(() => {
    if (!loadingWeights) {
      return weights.map((weight) => {
        return {
          id: weight.key,
          label: weight.key,
          value: weight.key,
        };
      });
    }
  }, [weights, loadingWeights]);

  //select weight
  const selectWeight = (e) => {
    setFormData({ ...formData, weight: e.value });
  };

  //function to close modal
  const close = () => {
    closeModal();
  };
  // console.log(rolesOptions);

  useEffect(() => {
    reset(formData);
  }, [formData, reset]);

  return (
    <Modal isOpen={modal} toggle={() => close()} className="modal-dialog-centered" size="lg">
      <ModalBody>
        <a
          href="#cancel"
          onClick={(ev) => {
            ev.preventDefault();
            close();
          }}
          className="close"
        >
          <Icon name="cross-sm"></Icon>
        </a>
        <div className="p-2">
          <h5 className="title">{type.add ? "Add Zone" : "Edit Zone"}</h5>
          <div className="mt-4">
            <Form className="row gy-4" noValidate onSubmit={handleSubmit(onSubmit)}>
              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Select Weight (kg) </label>
                  <div className="form-control-wrap">
                    <RSelect
                      options={weightOptions}
                      value={{
                        value: formData.weight,
                        label: formData.weight,
                      }}
                      onChange={(e) => selectWeight(e)}
                    />
                  </div>
                  {errors.weight && <span className="invalid">{errors.weight.message}</span>}
                </div>
              </Col>

              <Col md="6">
                <div className="form-group">
                  <label className="form-label">Amount</label>
                  <input
                    className="form-control"
                    type="number"
                    {...register("amount", { required: "This field is required" })}
                    defaultValue={formData.amount}
                    onChange={(e) => setFormData({ ...formData, amount: e.target.value })}
                  />

                  {errors.amount && <span className="invalid">{errors.amount.message}</span>}
                </div>
              </Col>
              <Col size="12">
                <ul className="align-center flex-wrap flex-sm-nowrap gx-4 gy-2">
                  <li>
                    <Button color="primary" size="md" type="submit">
                      {type.add ? "Add Weight" : "Edit Weight"}
                    </Button>
                  </li>
                  <li>
                    <a
                      href="#cancel"
                      onClick={(ev) => {
                        ev.preventDefault();
                        close();
                      }}
                      className="link link-light"
                    >
                      Cancel
                    </a>
                  </li>
                </ul>
              </Col>
            </Form>
          </div>
        </div>
      </ModalBody>
    </Modal>
  );
};
export default WeightModal;
